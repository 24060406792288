<template>
  <div
    style="height: 100vh !important;"
    class="d-flex flex-column justify-content-center w-100 align-items-center"
  >
    <div class="loading">
      <div class="effect-1 effects" />
      <div class="effect-2 effects" />
      <div class="effect-3 effects" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Loading',
  async mounted() {
    const { requestLogin } = this.$route.query
    if (requestLogin) {
      await this.$auth.loginWithRedirect({ redirect_uri: `${window.location.origin}/callback?groupId=${this.$route.query.groupId}` })
    } else {
      this.$router.push('/')
    }
  },
}
</script>

<style scoped></style>
